:root {

    /* Colours */
    --color-blue: #3eb0ef;
    --color-green: #30cf43; /* old: #45C32E */
    --color-red: #ef1818;
    --color-base: #15171A;
    --color-darkgrey: #343f44;
    --color-middarkgrey: #626D79;
    --color-midgrey: #738a94;
    --color-lightgrey: #c5d2d9;
    --color-whitegrey: #e5eff5;
    --color-white: #ffffff;
    --color-offwhite: #f1f2f5;
    --color-border: #e5e8ed;
    --color-wash: #f4f8fb;
    --color-purple: #ad26b4;

    /* Fonts */
    --font-sans-serif: 'Inter', sans-serif;
    --font-monospace: Menlo, ui-monospace, SFMono-Regular, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    --serif: Georgia, serif;

}

@supports (font-variation-settings: normal) {
    :root {
        --font-sans-serif: 'Inter var', sans-serif;
    }
}
