*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 62.5%;
    background: var(--color-wash);
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    color: color-mod(var(--color-base) l(+40%));
    font-family: var(--font-sans-serif);
    font-size: 1.5rem;
    line-height: 1.6em;
    font-weight: 400;
    font-style: normal;
    background: #fff;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::selection {
    text-shadow: none;
    background: #cbeafb;
}

p,
ul,
ol,
li,
dl,
dd,
hr,
pre,
form,
table,
video,
figure,
figcaption,
blockquote {
    margin: 0;
    padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video,
stream {
    vertical-align: middle;
}

img {
    display: block;
    border: 0;
    max-width: 100%;
    height: auto;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

legend {
    padding: 0;
    border: 0;
}

td,
th {
    padding: 0;
}

code,
kbd,
pre,
samp {
    font-family: var(--font-monospace);
    font-size: 1em;
}

pre,
code {
    font-family: var(--font-monospace);
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    tab-size: 4;
    hyphens: none;
}

/* Inline code */
:not(pre) > code {
    vertical-align: middle;
    padding: 0.2em 0.4em;
    border: #e1eaef 1px solid;
    font-weight: 400 !important;
    font-size: 0.8em;
    line-height: 1em;
    text-shadow: none;
    color: #e40053;
    background: #f0f6f9;
    border-radius: 0.3em;
    white-space: normal;
}

pre {
    overflow: auto;
    max-width: 100%;
    width: 100%;
    padding: 2.4rem 3.2rem;
    color: #fff;
    font-size: 1.3rem;
    line-height: 1.8em;
    background: color-mod(var(--color-base) l(+5%));
    border-radius: 8px;
}

kbd {
    display: inline-block;
    padding: 0.35em 0.42em;
    color: #73787d;
    font: 0.8em "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    line-height: 1em;
    background-color: #fafbfc;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #959da5, inset 0 -2px 0 #959da5;
    vertical-align: middle;
    overflow: hidden;
}

hr {
    position: relative;
    display: block;
    margin: 2em 0;
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid currentcolor;
    opacity: 0.1;
}

svg:not(:root) {
    overflow: hidden;
}

mark {
    background-color: #fdffb6;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

ol,
ul {
    padding-left: 2.5em;
}

ol ol,
ul ul,
ul ol,
ol ul {
    margin: 0.5em 0 1em;
    padding-left: 2em;
}

li {
    margin: 0.8em 0;
    line-height: 1.5em;
}

dt {
    float: left;
    margin: 0 20px 0 0;
    width: 120px;
    color: var(--color-darkgrey);
    font-weight: 500;
    text-align: right;
}

dd {
    margin: 0 0 5px 0;
    text-align: left;
}

blockquote {
    margin: 1.6em 0;
    padding: 0 1.6em 0 1.6em;
    border-left: var(--color-whitegrey) 0.5em solid;;
}

blockquote p {
    margin: 0.8em 0;
    font-size: 1.2em;
    line-height: 1.55em;
    font-weight: 300;
}

blockquote cite {
    font-weight: bold;
}

blockquote cite a {
    font-weight: normal;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: var(--color-base);
    line-height: 1.15em;
    font-weight: 700;
}

h1 {
    font-size: 4.1rem;
    letter-spacing: -0.025em;
}
@media (max-width: 500px) {
    h1 {
        font-size: 2.7rem;
    }
}

h2 {
    font-size: 3.6rem;
    letter-spacing: -0.025em;
}
@media (max-width: 500px) {
    h2 {
        font-size: 2.2rem;
    }
}

h3 {
    font-size: 3.2rem;
}
@media (max-width: 500px) {
    h3 {
        font-size: 1.8rem;
    }
}

h4 {
    font-size: 2.6rem;
}

h5 {
    font-size: 2.4rem;
}

h6 {
    font-size: 2.2rem;
}

a {
    color: var(--color-base);
}
a:not([class]) {
    font-weight: 500;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    background-color: transparent;
}
a:not([class]):hover {
    text-decoration: underline;
}

button:active,
button:focus,
button:hover,
a:active,
a:hover,
a:focus {
    outline: 0;
}

p,
ul,
ol,
dl,
blockquote {
    margin: 0 0 1.5em 0;
}


/* Forms
/* ---------------------------------------------------------- */

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

label {
    display: block;
    margin: 0 0 0.3em 0;
    font-size: 0.9em;
    font-weight: 600;
    color: var(--color-base);
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    color: inherit;
    font: inherit;
    -webkit-appearance: none;
}
button {
    border: none;
    background: transparent;
    overflow: visible;
}
button,
select {
    text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;

    -webkit-appearance: button;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}
input {
    line-height: normal;
}
input:focus {
    outline: none;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    -webkit-appearance: auto;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
input[type="search"] {
    box-sizing: content-box;

    -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

input[type=range] {
    width: 100%;
    background: transparent;
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-ms-track {
    width: 100%;
    border-color: transparent;
    color: transparent;
    background: transparent;
    cursor: pointer;
}

textarea {
    overflow: auto;
    resize: vertical;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2em;
}

h1 {
    font-size: 3.6rem;
    letter-spacing: -0.025em;
    text-wrap: pretty;
}

label {
    font-size: 1.5rem;
}
