@import "components/vars.css";
@import "components/global.css";
@import "components/404.css";

/* -------------------- TEMPLATE --------------------
This is to simulate Ghost Admin sidebar,
don't include in final styles. */

/* .viewport:before {
    content: "";
    display: block;
    width: 400px;
    background: #ccc;
} */

.support-banner {
    margin: 4vmin auto;
    padding: 0 4vw;
    width: 100%;
}

.support-banner-callout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 32px;
    border-radius: 0.8rem;
    background: #faf7e2;
    box-shadow:
        0 0 1px rgb(0 0 0 / 3%),
        0 3px 10px -5px rgb(0 0 0 / 10%);
    border: rgba(0, 0, 0, 0.05) 1px solid;
}

.support-banner-emoji {
    padding: 0 0.8rem 0 0;
}

.support-banner-text,
.support-banner-text a {
    font-size: 1.4rem;
    line-height: 1.5em;
    text-align: left;
    color: var(--color-base);
}

/* Header Navigation */

.pro-nav {
    display: grid;
    max-width: 1100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0;
    margin: -2vmin auto 4vmin;
    padding: 0 4vw;
    text-align: center;
}

.pro-nav a {
    display: inline-block;
    text-align: center;
    padding: 7px;
    border: 1px solid var(--color-border);
    color: #000;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    background: #fff;
}

.pro-nav a:first-child {
    border-radius: 5px 0 0 5px;
}

.pro-nav a:last-child {
    border-radius: 0 5px 5px 0;
}

.pro-nav a.active {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-color: var(--color-green);
    background: var(--color-green);
}

.domain-nav {
    display: flex;
    max-width: 1100px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: -2vmin 0 auto;
    padding: 0;
    background: #fff;
    border: 1px solid var(--color-border);
    border-radius: 5px;
}

/* ---------- Duplicated Form styles from main.css ----------- */
.gh-form {
    position: relative;
    display: block;
    margin: 0 0 40px;
    padding: 14px 20px 20px;
    width: 520px;
    max-width: 520px;
}
@media (max-width: 520px) {
    .gh-form {
        display: block;
        width: 100%;
        max-width: 100%;
    }
}

.gh-form header,
.gh-modal-content header {
    margin: 0 0 28px 0;
    text-align: center;
}

.gh-form header p {
    margin: 0.4em 0 0 0;
    font-size: 1.9rem;
    line-height: 1.4em;
}
@media (max-width: 500px) {
    .gh-form header p {
        font-size: 1.5rem;
    }
}

.gh-form footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    margin: 40px 0 0 0;
    line-height: 1.4em;
}

.gh-input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-bottom: 1.6rem;
}

.gh-input-group p {
    display: block;
    margin: 0.3em 0 0 0;
    font-size: 0.9em;
}

.gh-input-group + .gh-btn {
    margin-top: 30px;
}

.gh-input,
.gh-select,
select {
    display: block;
    padding: 1.2rem 1.6rem;
    min-height: 44px;
    width: 100%;
    border: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    color: var(--color-base);
    font-size: 1.5rem;
    line-height: 1em;
    font-weight: normal;
    user-select: text;
    border-radius: 0.5rem;
    background: #fff;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

.gh-select,
select {
    cursor: pointer;
}

::-webkit-input-placeholder {
    color: color-mod(var(--color-midgrey) l(+15%));
}

:-ms-input-placeholder {
    color: color-mod(var(--color-midgrey) l(+15%));
}

textarea {
    min-width: 250px;
    min-height: 10rem;
    max-width: 500px;
    width: 100%;
    height: auto;
    line-height: 1.5;
    user-select: text;
    resize: vertical;
}

.gh-modal textarea {
    max-width: 100%;
}

textarea.gh-input {
    line-height: 1.5em;
}

.gh-input:focus,
.gh-input.focus,
.gh-select:focus,
select:focus,
textarea:focus {
    outline: 0;
    border-color: #87d20d;
    box-shadow: 0px 0px 0px 3px rgba(26, 170, 96, 0.15);
}
a:not([class]) {
    text-decoration: underline;
}

.gh-input.error {
    border-color: var(--color-red);
    box-shadow: 0px 0px 0px 3px color-mod(var(--color-red) a(0.15));
}

.gh-input:-webkit-autofill {
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;

    -webkit-text-fill-color: color-mod(var(--color-midgrey) l(-5%));
    -webkit-animation: 1ms void-animation-out;
}

.gh-input.stripe-autofill {
    background-color: #fff !important;
}

.form-error {
    margin: 10px 0 0;
    font-weight: 500;
}

.form-error p {
    color: var(--color-red);
}

.form-error .add-card-btn {
    color: var(--color-red);
    text-decoration: underline;
}

/* Modals
/* ---------------------------------------------------------- */

.gh-modal {
    z-index: 2;
    overflow: hidden;
    margin: 0 auto 4rem !important;
    padding: 5.4rem 3.2rem !important;
    max-width: 58rem !important;
    width: 100% !important;
    outline: none;
    background-color: #fff;
    border-radius: 0.8rem;
    box-shadow:
        0 0 1px rgba(0, 0, 0, 0.03),
        0 10px 32px -5px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 44rem) {
    .gh-modal {
        padding: 4.6rem 5.4rem 5.4rem !important;
    }
}

@media (min-width: 768px) {
    .gh-modal {
        margin: 10vh auto !important;
    }
}

@media (min-width: 768px) {
    .gh-modal {
        margin: 10vh auto !important;
    }
}

.gh-modal-close {
    position: absolute;
    top: 8px !important;
    right: 8px !important;
    padding: 1.6rem !important;
    cursor: pointer;
}

.gh-modal-close svg {
    width: 1.8rem;
    height: auto;
    fill: var(--color-midgrey);
}

.gh-modal-close svg:hover {
    fill: var(--color-darkgrey);
}

.gh-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(84, 102, 109, 0.5) !important;
}

.gh-modal-body-open {
    z-index: 9999;
    overflow: hidden;
}

.gh-modal-content {
    position: relative;
    height: auto;
    border: none;
    color: var(--color-midgrey);
    background: #fff;
}

.gh-modal-content h1 {
    margin-top: 0;
    font-size: 3.4rem;
}

.gh-modal-content p {
    margin-bottom: 32px;
}

.gh-modal .gh-form {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.gh-modal .gh-form .gh-input {
    margin-bottom: 15px;
}

.gh-modal .gh-form .gh-btn {
    min-height: 48px;
    font-size: 1.5rem;
}

.gh-modal-text-only {
    margin-top: 12px;
}

/* -------------------- UTILITY -------------------- */

.hidden {
    display: none;
}

.red {
    color: var(--color-red);
}

.button-group {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.button-group-right {
    justify-content: flex-end;
}

.button-group-right > .gh-btn:not(.gh-btn:last-of-type) {
    margin-right: 2vmin;
}

.gh-btn {
    display: inline-block;
    min-height: 44px;
    padding: 1.2rem 1.5rem;
    outline: none;
    font-size: 1.6rem;
    line-height: 1em;
    color: #fff;
    text-align: center;
    border: var(--color-base) 1px solid;
    background: var(--color-base);
    text-decoration: none !important;
    user-select: none;
    border-radius: 0.6rem;
    transition: background 0.3s ease;

    -webkit-font-smoothing: subpixel-antialiased;
}
.gh-btn:hover {
    background: #000;
    transition: background 0.3s ease;
}

.gh-btn-green {
    background: var(--color-green);
    border-color: var(--color-green);
}
.gh-btn-green:hover {
    background: var(--color-green);
}

.gh-btn-outline {
    background: transparent;
    border-color: var(--color-border);
    color: var(--color-midgrey);
}
.gh-btn-outline:hover {
    background: transparent;
    border-color: var(--color-green);
    color: var(--color-green);
}

.gh-btn-outline.green {
    background: transparent;
    border-color: var(--color-green);
    color: var(--color-green);
}

.gh-btn-outline.green:focus {
    box-shadow: 0px 0px 0px 3px rgba(26, 170, 96, 0.15);
}

.gh-btn-block {
    display: block;
    width: 100%;
}

.gh-btn-sm {
    min-height: 38px;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    line-height: 1em;
}

.gh-btn.disabled,
.gh-btn[disabled],
fieldset[disabled] .gh-btn {
    box-shadow: none;
    opacity: 1;
    cursor: not-allowed;
    pointer-events: none;
}

.gh-btn.disabled:not(.spinner),
.gh-btn[disabled]:not(.spinner),
fieldset[disabled].gh-btn :not(.spinner) {
    opacity: 0.8;
}

.gh-btn-red {
    background: var(--color-red);
    border-color: var(--color-red);
}
.gh-btn-red:hover {
    background: var(--color-red);
}

/*
/* Loading Button Spinner
/* ---------------------------------------------------------- */
/*

Usage: Swap out button>span text with HTML

<a class="gh-btn gh-btn-blue">
    <span>
        <div class="gh-spinner"></div>
    </span>
</div>

*/

.gh-spinner {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border: rgba(0, 0, 0, 0.2) solid 4px;
    border-radius: 100%;
    vertical-align: text-bottom;
    animation: spin 1s linear infinite;
}

.gh-spinner:before {
    content: "";
    display: block;
    margin-top: 9px;
    width: 4px;
    height: 4px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 100%;
}

.gh-btn-green .gh-spinner,
.gh-btn-block .gh-spinner {
    border-color: rgba(255, 255, 255, 0.2);
}

.gh-btn-green .gh-spinner:before,
.gh-btn-block .gh-spinner:before {
    background: rgba(255, 255, 255, 0.6);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* iOS style toggle button
/* ---------------------------------------------------------- */

.toggle-inline {
    display: flex;
    flex-shrink: 0;
}

.toggle-inline + .toggle-inline {
    margin: 0.8em 0 0;
}

.toggle-inline label {
    padding: 0 0 0 10px;
}

input.toggle-btn {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #d9dadc;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 #ffffff;
    transition-duration: 200ms;
}

input.toggle-btn:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 26px;
    height: 26px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.toggle-btn:checked {
    border-color: var(--color-green);
    box-shadow: inset 20px 0 0 0 var(--color-green);
}

input.toggle-btn:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

/* Loading state */
.gh-loading-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    justify-content: center;
    left: 0;
    padding-bottom: 8vh;
}

.gh-loading-content.basic-auth {
    z-index: 1000;
}

.gh-loading-spinner {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: -2px 0;
    width: 50px;
    height: 50px;
    border: rgba(0, 0, 0, 0.1) solid 1px;
    border-radius: 100px;
    animation: spin 1s linear infinite;
}

.gh-loading-spinner:before {
    content: "";
    display: block;
    margin-top: 7px;
    width: 7px;
    height: 7px;
    background: #4c5156;
    border-radius: 100px;
    z-index: 10;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* MAIN LAYOUT */

.viewport {
    width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    background: linear-gradient(
        315deg,
        rgba(239, 239, 239, 1) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mainhead {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: calc(40px + 4vmin) 0 60px;
}

.mainhead p {
    max-width: 630px;
    margin: 2rem 20px 0;
    text-align: center;
    font-size: 2rem;
    line-height: 1.4em;
}

.mainhead strong {
    font-weight: 500;
    color: var(--color-base);
}

.home-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.home-link svg {
    height: 40px;
}

.home-link img {
    height: 55px;
    width: auto;
}

.home-link .pro-logo-svg {
    height: 26px;
    margin: 0 0 2px 5px;
}

.main {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 4vw;
}

.main-med {
    max-width: 1300px;
}

.main-wide {
    max-width: 1540px;
}

@media (max-width: 600px) {
    .mainhead p {
        font-size: 1.6rem;
    }
}

/* -------------------- ACCOUNT PAGE -------------------- */

.accountgrid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
}

.box-wrap {
    display: flex;
    flex-direction: column;
}

.box-label {
    display: block;
    margin: 0 0 0.3em 0;
    font-weight: 600;
    color: var(--color-base);
}

.box {
    width: 100%;
    height: 100%;
    padding: 32px;
    border: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    color: var(--color-base);
    background: #fff;
    border-radius: 0.8rem;
}

.box-plandetails {
    grid-column: span 2;
}

.plandetails {
    display: flex;
    flex-direction: column;
}

.plandetails-info {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr auto;
}

.plandetails h2 {
    margin: 0 0 0.5em;
    font-size: 2.8rem;
}

.plandetails .stripe-portal-button {
    min-width: 220px;
}
@media (max-width: 600px) {
    .plandetails h2 {
        font-size: 2rem;
    }
    .plandetails .stripe-portal-button {
        grid-column: span 2;
    }
}

.plandetails p:not(.red, .error-msg) {
    margin: 0;
    font-size: 1.8rem;
    opacity: 0.5;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.plandetails p.red {
    margin: 25px auto 0;
    font-size: 1.8rem;
    opacity: 0.8;
}

.plandetails .cancel-warning {
    margin: 25px 0 -5px 0;
    padding: 25px 0;
    border-top: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
}

.plandetails .cancel-warning-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.plandetails .cancel-warning-content ~ div {
    flex: 1 0 auto;
    margin-right: 25px;
}

.plandetails .cancel-warning-content p,
.plandetails .cancel-warning-content p.red {
    opacity: 1;
    display: block;
    overflow: visible;
    font-weight: 500;
}

.plandetails .cancel-warning .cancel-summary {
    display: flex;
}

.plandetails .cancel-warning .reactivate-link-wrapper {
    margin-left: 10px;
    font-size: 1.8rem;
    font-weight: 600;
    opacity: 0.9;
    transition: opacity 0.2s ease-in-out;
    user-select: none;
}

.plandetails .cancel-warning .reactivate-link-wrapper:hover {
    opacity: 1;
}

.plandetails .cancel-warning .reactivate-link-wrapper.disabled {
    color: var(--color-base);
    opacity: 0.4;
    cursor: not-allowed;
}

.plandetails .cancel-warning .reactivate-link {
    text-decoration: underline;
    padding: 0 0.1em;
}

.plandetails .cancel-warning .error-msg {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-red);
    margin: 10px 0 0;
}

@media (max-width: 680px) {
    .plandetails .cancel-warning-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .plandetails .cancel-warning-content ~ div {
        margin: 0 0 25px;
    }

    .plandetails .cancel-warning-content .cancel-action {
        width: 100%;
        flex: 1 1 auto;
        margin-top: 25px;
    }
}

.plandetails-breakdown {
    margin: 25px 0 0 0;
}

.plandetails footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 25px 0 -5px 0;
    padding: 25px 0 0 0;
    border-top: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    font-size: 1.8rem;
    letter-spacing: -0.01em;
}

.plandetails footer div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0 0 0;
}

.plandetails footer .green {
    color: var(--color-green);
}

.plandetails footer strong {
    font-weight: 600;
}

.staticfield + .staticfield {
    margin-top: 30px;
}

.staticfield header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.staticfield-label {
    display: block;
    margin: 0 0 0.3em 0;
    font-weight: 600;
    color: var(--color-base);
}

.staticfield-link {
    text-decoration: none;
    color: var(--color-green);
}

/* Browser user agents add padding when this class is used as button */
button.staticfield-link {
    padding: 0;
}

.staticfield-link:hover {
    text-decoration: underline;
}

.staticfield-link.disabled {
    color: var(--color-base);
    opacity: 0.5;
    cursor: default;
    user-select: none;
}

.staticfield-link.disabled:hover {
    text-decoration: none;
}

.staticfield-card {
    display: flex;
    flex-direction: column;
}

.staticfield-cardnumber {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
}

.staticfield-cardnumber img {
    display: inline-block;
    margin: 0 10px 0 0;
    flex: 0 0 auto;
}

.staticfield-card-expiry {
    font-size: 0.9em;
    line-height: 1.3em;
    opacity: 0.5;
}

.staticfield-card p {
    margin-bottom: 0;
}

.staticfield-value {
    font-size: 1.6rem;
    opacity: 0.5;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.billing-contact-header,
.update-card-dunning-header {
    display: flex;
    flex-direction: column;
}

.invoices {
    width: 100%;
    margin: 5px 0 -10px 0;
    display: flex;
    flex-direction: column;
}

.invoices-item {
    width: calc(100% + 30px);
    margin: 0 -15px;
    padding: 10px 15px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr auto;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    border-radius: 6px;
}

.invoices-item:hover {
    color: var(--color-base);
    background: #fafafa;
}

.invoices-item strong {
    font-weight: 500;
    color: var(--color-base);
}

.invoice-price {
    display: flex;
    align-items: center;
}

.invoice-item {
    display: flex;
    align-items: center;
}

.invoice-arrow {
    flex: 0 0 auto;
    margin: 0 0 0 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 1;
    color: #fff;
    background: var(--color-base);
    border-radius: 100%;
}

.invoice-details {
    padding: 3rem;
    margin: 0 0 1.5em;
    font-family: var(--font-monospace);
    color: var(--color-base);
    background: var(--color-whitegrey);
    border-radius: 0.6rem;
}

.footer {
    display: flex;
    align-items: center;
    margin: 30px 0 60px;
}

.footer button[data-test-btn="open-cancel"] {
    margin: 0 24px 0 0;
}

.footer a {
    display: inline-block;
    margin: 0;
    font-size: 1.5rem;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

@media (max-width: 1000px) {
    .accountgrid {
        grid-template-columns: 1fr;
    }
    .box-plandetails {
        grid-column: span 1;
    }
}

/* Tooltip */
/* https://dev.to/r3zu3/pure-css-tooltip-1k3j */

.tooltip {
    width: fit-content;
    position: relative;
    font-size: 1.1rem;
}

.tooltip > .staticfield-link.disabled {
    font-size: 1.5rem;
    cursor: help !important;
}

.tooltip > .gh-btn.disabled {
    font-size: 1.5rem;
    cursor: help !important;
}

.tooltip::before {
    position: absolute;
    content: "";
    background-color: var(--color-base);
    width: 15px;
    height: 15px;
    z-index: 500;
    opacity: 0;
    transition: all cubic-bezier(0.17, 0.67, 0.5, 0.71) 150ms;
    pointer-events: none;
}

.tooltip::after {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 1.2rem 1.5rem;
    height: 30px;
    content: attr(data-tooltip-text);
    white-space: nowrap;
    background-color: var(--color-base);
    border-radius: 5px;
    color: #fff;
    text-align: center;
    z-index: 555;
    opacity: 0;
    transition: all cubic-bezier(0.17, 0.67, 0.5, 0.71) 100ms;
    pointer-events: none;
}

.tooltip-lg::after {
    height: 36px;
}

.tooltip:hover::before,
.tooltip:hover::after {
    opacity: 1;
}

.tooltip.t-top::before {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
}

.tooltip.t-top::after {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip.t-top:hover::before {
    transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip.t-top:hover::after {
    transform: translate(-50%, calc(-100% - 10px));
}

.tooltip.t-left::before {
    top: 30%;
    left: 0;
    transform: translate(0, 0%) rotate(45deg);
}

.tooltip.t-left::after {
    top: 0;
    left: 0;
}

.tooltip.t-left:hover::before {
    transform: translate(calc(-100% - 5px)) rotate(45deg);
}

.tooltip.t-left:hover::after {
    transform: translate(calc(-100% - 10px));
}

.tooltip.t-right::before {
    top: 30%;
    right: 0;
    transform: translate(0, 0%) rotate(45deg);
}

.tooltip.t-right::after {
    top: 0;
    right: 0;
}

.tooltip.t-right:hover::before {
    transform: translate(calc(100% + 5px)) rotate(45deg);
}

.tooltip.t-right:hover::after {
    transform: translate(calc(100% + 10px));
}

.tooltip.t-bottom::before {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
}

.tooltip.t-bottom::after {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip.t-bottom:hover::before {
    transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.tooltip.t-bottom:hover::after {
    transform: translate(-50%, calc(100% + 10px));
}

/* ------------------- PLAN SELECTOR ------------------- */

/* Extend Trial CTA */
.extend-trial {
    grid-gap: 30px;
    display: grid;
    grid-template-columns: 1fr minmax(140px, auto);
    align-items: center;
    padding: 20px 30px;
    margin: 0 auto 4vmin;
    width: 100%;
    border: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    color: var(--color-base);
    background: #fff;
    border-radius: 0.8rem;
}

.coupon {
    margin: -10px 20px -42px 20px;
    padding: 1.5rem;
    color: var(--color-base);
    border-top: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    border-right: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    border-left: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    border-radius: 0.8rem 0.8rem 0 0;
    background: #fff7dc;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.19em;
    text-align: center;
    z-index: 0;
}

.planselect {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    margin: 40px 0;
    border: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    color: var(--color-base);
    background: #fff;
    border-radius: 0.8rem;
    z-index: 1;
}

.planselect.plan-3 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.plan-disabled header {
    opacity: 0.5;
}
.plan-disabled .gh-btn:hover {
    cursor: not-allowed;
}

.planselect section {
    padding: 40px 30px;
}

.planselect section + section {
    box-shadow: 1px 0 0 color-mod(var(--color-lightgrey) l(+7%)) inset;
}

@media (min-width: 1201px) {
    .planselect section.highlight {
        position: relative;
        box-shadow: 0 0 0 1px var(--color-green);
    }
    .planselect section.highlight:before {
        content: "Most popular";
        display: block;
        position: absolute;
        bottom: 100%;
        left: -1px;
        right: -1px;
        text-align: center;
        color: #fff;
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 700;
        letter-spacing: -0.02em;
        padding: 9px 0;
        border-radius: 5px 5px 0 0;
        background: var(--color-green);
    }
}

.planselect header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.planselect header h3 {
    font-size: 2.2rem;
    letter-spacing: -0.01em;
}

.planselect .price {
    display: flex;
    margin: 25px 0 30px;
}

.planselect .price-sym {
    align-self: flex-start;
    font-size: 2rem;
    opacity: 0.5;
    margin-top: 4px;
}

.planselect .price-amount {
    margin: 0 4px;
    font-size: 4.4rem;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -0.05em;
}

.planselect .price-term {
    align-self: flex-end;
    margin: 0 -20px 0 0;
    font-size: 1.8rem;
    opacity: 0.5;
}

.planselect .price-more {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 25px 0 24px;
    opacity: 0.7;
}

.planselect .price-more p {
    margin: 0;
}

.planselect .price-more a {
    -webkit-text-decoration-skip: ink;
    background-color: transparent;
    color: #26a6ed;
    text-decoration: none;
    text-decoration-skip-ink: auto;
}

.planselect .price-unavailable {
    line-height: 1.3em;
}

.price-unavailable p {
    margin-bottom: 5px;
}

.planselect .plancalc {
    padding-top: 33px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plancalc header h3 {
    margin: 0 0 20px 0;
    font-size: 1.8rem;
    font-weight: 500;
}

.plancalc-memberscount {
    display: flex;
    flex-direction: column;
}

.plancalc-memberscount-number {
    font-size: 3.8rem;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.02em;
}

.plancalc-memberscount-label {
    font-size: 1.8rem;
    line-height: 1.3em;
    opacity: 0.5;
}

.plancalc-slider,
.plancalc-slider:focus {
    margin: 30px auto;
    width: 100%;
    max-width: 480px;
    background: none;
    appearance: none;
}

.plancalc-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    background: #ebeef0;
    border-radius: 4.5px;
    cursor: pointer;
}

.plancalc-slider::-webkit-slider-thumb {
    margin-top: -8.2px;
    width: 22px;
    height: 22px;
    border: 6px solid #fff;
    background: var(--color-green);
    border-radius: 100%;
    box-shadow:
        0px 1px 4px -1px rgba(0, 0, 0, 0.3),
        0 0 1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.plancalc-slider::-moz-range-track {
    width: 100%;
    height: 6px;
    background: #ebeef0;
    border-radius: 4.5px;
    cursor: pointer;
}

.plancalc-slider::-moz-range-thumb {
    width: 22px;
    height: 22px;
    border: 6px solid #fff;
    background: var(--color-green);
    border-radius: 100%;
    box-shadow:
        0px 1px 4px -1px rgba(0, 0, 0, 0.3),
        0 0 1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.plancalc-slider::-ms-track {
    height: 6px;
}

.plancalc-slider::-ms-fill-lower {
    border: 0.2px solid #010101;
    background: #ebeef0;
    border-radius: 9px;
    box-shadow:
        1px 1px 1px #000,
        0 0 1px #0d0d0d;
}

.plancalc-slider::-ms-fill-upper {
    border: 0.2px solid #010101;
    background: #ebeef0;
    border-radius: 9px;
    box-shadow:
        1px 1px 1px #000,
        0 0 1px #0d0d0d;
}

.plancalc-slider::-ms-thumb {
    width: 8px;
    height: 22px;
    height: 6px;
    border: 6px solid #fff;
    background: var(--color-green);
    border-radius: 100%;
    box-shadow:
        0px 1px 4px -1px rgba(0, 0, 0, 0.3),
        0 0 1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.plancalc-slider:focus::-ms-fill-lower {
    background: #ebeef0;
}

.plancalc-slider:focus::-ms-fill-upper {
    background: rgba(13, 13, 13, 0.25);
}

.plancalc-slider-label {
    font-size: 1.1rem;
    line-height: 1em;
    opacity: 0.5;
    margin: 0 auto 20px;
    font-weight: 400;
}

.plancalc p {
    max-width: 460px;
    margin: 0 auto 20px;
    text-align: left;
    opacity: 0.6;
}

.plancalc a {
    max-width: 460px;
    margin: 30px auto;
    width: 100%;
    position: relative;
}

.plancalc a span.link-ext {
    margin: 0 0 0 6px;
    text-decoration: none;
    position: absolute;
    bottom: 1px;
}

.plancalc footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -30px -10px;
    padding: 25px 30px 0;
    border-top: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
}

.plancalc footer div {
    display: flex;
    flex-direction: column;
}

.plancalc footer div strong {
    font-size: 1.7rem;
    line-height: 1.4em;
}

.plancalc footer div span {
    line-height: 1.4em;
    color: rgba(0, 0, 0, 0.6);
}

.plancalc footer div span em {
    font-style: normal;
    font-weight: 600;
    color: var(--color-base);
}

.plan-features,
.plan-addons {
    margin: 40px 0 0 0;
    padding: 0px 0 0 15px;
    list-style-type: "✔";
    list-style-position: outside;
}

.plan-addons {
    margin-top: 0;
    list-style-type: "✚";
}

.plan-features li,
.plan-addons li {
    padding: 0 0 0 5px;
}

.planselector-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 0 40px 0;
    text-align: center;
}

.planselector-footer.two-columns {
    grid-template-columns: 1fr 1fr;
}

.planselector-footer p {
    margin-bottom: 5px;
}

.planselector-footer a {
    text-decoration: underline;
}

.footer-cancel {
    text-align: left;
}

.footer button,
.footer-cancel button {
    padding: 0;
}

.planselector-footer.two-columns .footer-compare {
    text-align: right;
    align-self: flex-end;
}

@media (max-width: 1300px) {
    .coupon {
        margin-bottom: 0;
        margin-top: 10px;
    }
    .planselect {
        margin-top: 0;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .plancalc {
        grid-column: span 4;
        box-shadow: 0 1px 0 0 color-mod(var(--color-lightgrey) l(+7%));
    }
    .planselect section:nth-child(2) {
        box-shadow: none;
    }
    .plancalc header h3 br {
        display: none;
    }
    .plancalc p {
        text-align: center;
    }
    .planselect.plan-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .planselect.plan-3 .plancalc {
        grid-column: span 3;
    }
}

@media (max-width: 1200px) {
    .planselect {
        grid-template-columns: 1fr 1fr;
        overflow: hidden;
    }
    .plancalc {
        grid-column: span 2;
    }
    .planselect section {
        box-shadow: 0 0 0 1px color-mod(var(--color-lightgrey) l(+7%)) !important;
    }

    .planselect.plan-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .planselect.plan-3 .plancalc {
        grid-column: span 3;
    }
}

@media (max-width: 800px) {
    .extend-trial {
        margin-top: 0;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .trial-text {
        text-align: center;
    }
    .trial-action {
        width: 100%;
    }
    .extend-trial .gh-btn {
        width: 100%;
    }
    .planselect {
        grid-template-columns: 1fr;
    }
    .plancalc {
        grid-column: span 1;
    }
    .planselect section {
        box-shadow: 0 -1px 0 0 color-mod(var(--color-lightgrey) l(+7%)) inset !important;
    }
    .planselector-footer.two-columns,
    .planselector-footer {
        grid-template-columns: auto;
        justify-content: center;
        align-items: center;
        grid-row-gap: 4vmin;
    }
    .footer-cancel {
        display: flex;
        align-items: flex-end;
        grid-row: 2 / auto;
        justify-content: center;
    }

    .planselect.plan-3 {
        grid-template-columns: 1fr;
    }
    .planselect.plan-3 .plancalc {
        grid-column: span 1;
    }
}

/* ------------------- CHECKOUT PAGE ------------------- */

.checkoutgrid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 2fr 1fr;
}

.checkoutgrid > section {
    display: flex;
    flex-direction: column;
}

.checkoutgrid > section > div {
    margin-bottom: 30px;
}

.plandetails-split {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plandetails-split div {
    position: relative;
    margin: 0 0 0.5em 15px;
    font-size: 2.8rem;
    font-weight: 600;
    text-align: right;
}

.plandetails-split.annual div::before {
    background: var(--color-green);
    border-radius: 3px;
    color: #fff;
    content: "3 months free";
    font-size: 1.1rem;
    padding: 5px 10px;
    position: absolute;
    left: -110px;
    line-height: 1.5em;
}

.plandetails-split div span {
    font-weight: 400;
    opacity: 0.5;
}

.plandetails-split div small {
    font-weight: 400;
    opacity: 0.5;
    font-size: 1.8rem;
}

.plandetails-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px 0 0 0;
}

.plandetails-selected-content {
    display: grid;
    align-items: center;
    grid-gap: 15px;
    grid-template-columns: auto 1fr;
    font-size: 1.7rem;
    font-weight: 500;
}

.plandetails-selected-content strong {
    display: inline-block;
    padding: 10px 15px;
    background: var(--color-wash);
    border-radius: 5px;
}

.checkout-summary-switch {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.checkout-summary-switch button {
    display: inline-block;
    text-align: center;
    padding: 7px;
    border: var(--color-border) 1px solid;
    color: var(--color-midgrey);
}
.checkout-summary-switch button:hover {
    color: var(--color-darkgrey);
    border-color: var(--color-lightgrey);
    text-decoration: none;
}

.checkout-summary-switch button:first-child {
    border-right: none;
    border-radius: 5px 0 0 5px;
}

.checkout-summary-switch button:last-child {
    border-left: none;
    border-radius: 0 5px 5px 0;
}

.checkout-summary-switch button.active {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    border-color: var(--color-base);
    background: var(--color-base);
}
.checkout-summary-switch button.active:hover {
    cursor: default;
}

.checkout-summary-card {
    display: flex;
    flex-direction: column;
    margin: 20px 0 30px;
    padding: 0 0 30px;
    border-bottom: var(--color-border) 1px solid;
}

.checkout-summary-breakdown {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.checkout-summary-breakdown > div {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
}

.checkout-summary-breakdown > div.highlighted {
    background: #faf7e2;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
}

.checkout-summary-breakdown > div.grey > span:not(:first-of-type) {
    color: var(--color-base);
    opacity: 0.5;
}

.checkout-summary-breakdown > div.green > span:not(:first-of-type) {
    color: var(--color-green);
}

.checkout-summary-cta .gh-btn {
    margin-top: 16px;
}

.checkout-summary-cta p:not([class]) {
    color: rgba(0, 0, 0, 0.5);
}

.checkout-summary-cta p strong {
    color: var(--color-base);
    font-weight: 500;
}

.checkout-summary-cta div:not([class]) {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 10px 0;
    align-items: center;
    font-weight: 500;
}

.box-addon-item + .box-addon-item {
    margin: 30px 0 0 0;
    padding: 30px 0 0 0;
    border-top: var(--color-border) 1px solid;
}

.box-addon h3 {
    margin: 0 0 0.5em 0;
    font-size: 2.4rem;
    font-weight: 600;
}

.box-addon p {
    max-width: 80%;
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
}

.box-addon p strong {
    color: var(--color-base);
}

.box-addon .plandetails-split > div {
    font-size: 2.4rem;
}

.box-addon-item footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 0 0;
}

.box-addon-item footer a:not(.gh-btn) {
    font-size: 1.6rem;
}

.addon-desc-toggle {
    padding: 0;
}

@media (max-width: 1300px) {
    .checkoutgrid {
        grid-template-columns: 1fr;
    }
}

/* ------------------- DOMAIN PAGE ------------------- */

.domain {
    display: flex;
    justify-content: center;
}

.domain-nav .link-back {
    display: block;
    text-decoration: none;
    margin: 4px 0;
    font-weight: 500;
    padding: 5px 15px;
}

.domain-nav .actions {
    display: flex;
    margin: 4px 0;
    font-weight: 500;
    padding: 5px 15px;
}

.domain-nav .actions button {
    padding: 0 15px 0 0;
    margin: 0;
}

.domain-nav .actions button {
    text-decoration: none;
    font-weight: 500;
}

.domaingrid {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;
    width: 100%;
}

.domaingrid header {
    margin-bottom: 35px;
}

.domaingrid header h2 {
    text-align: center;
    margin-top: 24px;
}

.domaingrid header .subheader {
    text-align: center;
    font-size: 1.7rem;
    letter-spacing: -0.1px;
    margin-top: 4px;
}

.domain-form {
    display: grid;
    grid-template-columns: 1fr;
    background: #fff;
    border: 1px solid var(--color-whitegrey);
    border-radius: 8px;
    padding: 28px 32px 32px;
    margin-bottom: 32px;
    transition: grid-template-columns 0.3s ease;
}

.domain-form .form-error p {
    text-align: center;
}

.domain-verify,
.domain-remove {
    margin: 0;
    padding: 0;
    font-weight: 500;
    text-align: left;
}

.domain-verify {
    color: var(--color-base);
    font-size: 1.6rem;
    line-height: 1em;
    vertical-align: middle;
    min-height: 44px;
    outline: none;
    padding: 1.3rem 0;
}

.domain-remove {
    color: var(--color-red);
    font-weight: 400;
    text-decoration: underline;
    transition: font-weight 0.3s ease;
    margin-top: 20px;
}

.domain-deliverability {
    color: var(--color-base);
    font-weight: 400;
    text-align: left;
    transition: font-weight 0.3s ease;
    margin: 20px 0 0 0;
}

.domain-deliverability a {
    text-decoration: underline;
}

.domain-remove:hover {
    font-weight: 500;
}

.domain-form input + p {
    font-size: 0.9em;
    margin: 0.3em 0 0 0;
}

.domain-form input + p a {
    color: var(--color-green);
    text-decoration: none;
}

.domain-status-box {
    width: 100%;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 0.6rem;
}

.domain-status-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.domain-status-label {
    display: flex;
    align-items: center;
}

.domain-status-label > img {
    margin-right: 20px;
}

.domain-status-icon {
    width: 24px;
    height: 24px;
}

.sending-domain {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 30px;
    align-items: center;
    padding: 20px 0 35px;
}

.sending-domain-rapper > strong {
    margin-bottom: 0.5rem;
    display: inline-block;
}

.sending-domain-box {
    width: 100%;
    padding: 15px 20px;
    background-color: #f0f0f0;
    border-radius: 0 6rem 6rem 3rem;
    display: flex;
    align-items: center;
}

.sending-domain-box > span {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    flex-grow: 0;
}

.sending-domain-box strong {
    color: var(--color-middarkgrey);
    margin-right: 5px;
}

.sending-domain-box img {
    width: 22px;
    height: auto;
    margin-right: 10px;
}

.sending-domain-arrow {
    font-size: 2.4rem;
    color: var(--color-darkgrey);
    margin-top: 2.2rem;
}

.dns-form {
    background: #fff;
    border: 1px solid var(--color-whitegrey);
    border-radius: 8px;
    padding: 28px 32px 32px;
    margin-bottom: 32px;
}

.dns-table {
    width: 100%;
    color: var(--color-base);
}

.dns-table-content {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    justify-content: space-between;
    align-items: end;
    row-gap: 10px;
}

.domain-form .dns-table-content {
    grid-template-columns: repeat(3, 1fr);
}

.domain-form .dns-table-content:has(.dns-table-cfproxy) {
    grid-template-columns: repeat(3, 1fr) auto;
}

.dns-table-content p {
    margin: 0;
    padding: 0 20px 10px 0;
    font-size: 1.5rem;
    line-height: 1.3em;
    border-bottom: var(--color-border) 1px solid;
}

.dns-table-content:has(.dns-table-cfproxy) p {
    line-height: 1.5em;
}

.dns-table-content .dns-table-status {
    padding: 0 0 10px 0;
}

.dns-table-content .dns-table-status img {
    padding-left: 10px;
}

.dns-table-header {
    font-weight: 700;
}

.dns-table-content {
    font-weight: 400;
}

.dns-table-content .dns-table-value {
    word-break: break-word;
}

.dns-table-content .dns-table-value,
.dns-table-content .dns-table-name {
    position: relative;
    cursor: pointer;
}

.dns-table-content .dns-table-cfproxy img {
    padding-right: 10px;
    height: 1.5em;
    display: inline;
}

.copy-btn {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    position: absolute;
    bottom: 12px;
    right: 6px;
}

.dns-table-content .dns-table-value .copy-btn img,
.dns-table-content .dns-table-name .copy-btn img {
    opacity: 0;
    width: 15px;
    height: auto;
    transition: opacity 0.3s ease;
    background: #fff;
}

.dns-table-content .dns-table-value:hover .copy-btn img,
.dns-table-content .dns-table-name:hover .copy-btn img {
    opacity: 1;
}

/* ------------------- CANCEL ACCOUNT ------------------- */
.cancel-account-warning {
    color: var(--color-base);
    border-bottom: 1px solid var(--color-whitegrey);
    margin: 32px 0;
}

.cancel-account-warning p {
    margin-bottom: 12px;
}

.cancel-account-warning ul {
    padding-left: 1.9em;
}

.cancel-account input.toggle-btn {
    width: 40px;
    height: 24px;
}

.cancel-account input.toggle-btn:checked {
    box-shadow: inset 16px 0 0 0 var(--color-green);
}

.cancel-account input.toggle-btn:after {
    width: 20px;
    height: 20px;
}

.cancel-account input.toggle-btn:checked:after {
    top: 0;
    left: 16px;
    width: 22px;
    height: 22px;
}

.cancel-account label {
    font-size: 1em;
}

.cancel-account .gh-btn[disabled] {
    opacity: 0.5;
}

/* ------------------- BILLING MODE ------------------- */
.billing-mode-header {
    display: flex;
    flex-direction: column;
}

.billing-mode .gh-input-group {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.billing-mode label {
    color: var(--color-base);
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 30px auto;
    justify-content: center;
    margin-bottom: 3rem;
    user-select: none;
    cursor: pointer;
}

.billing-mode label span {
    opacity: 0.5;
    margin-top: 0.1em;
    font-weight: 400;
    line-height: 1.35em;
    font-size: 1.4rem;
    grid-column: 2;
    grid-row: 2;
}

.billing-mode .radio-btn {
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid var(--color-lightgrey);
    transition: 0.2s all linear;
    margin-right: 5px;
    position: relative;
    top: 4px;
    grid-column: 1;
    grid-row: 1;
}

.billing-mode .radio-btn:checked {
    border: 6px solid var(--color-green);
}
