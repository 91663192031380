.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.not-found h1 {
    font-size: 30vmin;
    text-align: center;
    margin: 0;
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(0,#1ec840,#21d0ce 20%,#1c7fee 40%,#5f15f2 55%,#ba0cf8 80%,#ff247d);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-box-decoration-break: clone;
}

.not-found h2 {
    text-align: center;
    font-weight: 600;
    color: rgba(0,0,10,0.45);
}
